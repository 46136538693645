import React from 'react'
import { Container } from '@mui/material'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import Layout from '../components/layout'

const ContactPage = () => {
    const {
        site: {
            siteMetadata: { siteUrl, siteTitle },
        },
    } = useSiteMetadata()

    const title = `お問い合わせ | ${siteTitle}`
    const description = `お問い合わせ`
    return (
        <Layout title={title} description={description}>
            <Container maxWidth="md" sx={{ pt: { xs: 3, sm: 6 }, mx: 'auto' }}>
                <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSd6oq0fAU2PMirnw9OJ-J4-BybyRanplr55_H1CBxkl5k-VfQ/viewform?embedded=true"
                    width="860"
                    height="650"
                    frameBorder={0}
                    marginHeight={0}
                    marginWidth={0}
                >
                    読み込んでいます…
                </iframe>
            </Container>
        </Layout>
    )
}

export default ContactPage
